import React from 'react';
import ViewDefinitionService from "../../../Services/ViewDefinitionService";
import LogUtil from "../../../../Utils/LogUtil";
import { Component, Message } from '../../internal';
import $ from 'jquery';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JSZip from "jszip/dist/jszip.min.js";

// datatable styles
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.css";

// datatable scripts
import "datatables/media/js/jquery.dataTables.js";
import "datatables.net-bs4/js/dataTables.bootstrap4.js";
import "datatables.net-colreorder/js/dataTables.colReorder.js";
import "datatables.net-select/js/dataTables.select.js";

import "datatables.net-buttons-bs4/js/buttons.bootstrap4.js";
import "jszip/vendor/FileSaver.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-responsive/js/dataTables.responsive.js";



import ServiceUtil from '../../../../Utils/ServiceUtil';
import DataUtil from '../../../../Utils/DataUtil';
import UiUtil from '../../../../Utils/UiUtil';

export class Table extends Component {

  constructor(props) {
    super(props);

    pdfMake.vfs = pdfFonts?.pdfMake?.vfs;
    window.JSZip = JSZip;
  };

  state = {
    loading: false,
    component: null,
    data: null
  };

  componentDidMount() {
    const component = this.props.component;
    const cols = component?.elements?.filter(c => c?.properties?.hidden != true);
    cols.elements = cols?.sort(function (a, b) {
      return a?.properties?.order - b?.properties?.order;
    });
    this.setState({ component, cols }, this.getData);
  };

  componentWillUnmount() {
    if (this.$tableElement) {
      try {
        this.$tableElement?.DataTable().destroy();
      }
      catch (e) { }
    }
  };

  getData = () => {
    if (this.state.component.data && !this.state.component?.data?.error)
      this.setState({ data: this.state.component?.data }, this.initDataTable);
    else if (this.props.data && !this.props.data?.error)
      this.setState({ data: this.props?.data }, this.initDataTable);
    else if (this.state.component?.properties?.initServiceUrl) {
      this.setState({ loading: true });
      new ViewDefinitionService().getViewByUrl(this.state.component?.properties?.initServiceUrl).then((response) => {
        this.setState({ loading: false, data: response.data }, this.initDataTable);
      }).catch((error) => {
        LogUtil.error(error);
        this.setState({ loading: false, data: [] });
      });
    }
  };

  initDataTable = () => {
    const options = this.props.options?.table?.datatable;
    let columnDefs = [/*{ targets: 'no-sort', orderable: false }*/];
    if (this.state.cols?.length && this.state.cols[this.state.cols.length - 1]?.elements?.[0]?.type == 'OPTIONS')
      columnDefs.push({ responsivePriority: 1, targets: -1, orderable: false });
    if (this.state.cols?.length && (this.state.cols[0]?.elements?.[0]?.type == 'CHECKBOX' || this.state.cols[0]?.elements?.[0]?.type == 'RADIO'))
      columnDefs.push({ responsivePriority: 1, targets: 1, orderable: false });
    let config = {
      responsive: options?.responsive != false,
      colReorder: { fixedColumnsLeft: 1 },
      columnDefs: columnDefs
    };
    if (options)
      config = { ...config, ...options };

    const $tableElement = $(this.tableElement);
    $tableElement.DataTable({ ...UiUtil.getDataTableConfig(), ...config });

    this.$tableElement = $tableElement;

    if(options?.canSelectAll) {
      // helper methods to add/remove bgc-h-* class when selecting/deselecting rows
      var _highlightSelectedRow = function(row) {
        row.querySelector('input[type=checkbox]').checked = true;
      };
      var _unhighlightDeselectedRow = function(row) {
        row.querySelector('input[type=checkbox]').checked = false;
      };
      $tableElement.on('click', 'th input[type=checkbox]', function() {
        if (this.checked) {
          $tableElement.DataTable().rows().every(function() {
            _highlightSelectedRow(this.node())
          });
        } else {
          $tableElement.DataTable().rows().every(function() {
            _unhighlightDeselectedRow(this.node())
          });
        }
      });
    }
  };

  renderData = () => {
    const dataPath = this.props.component?.properties?.dataPath;
    const data = (dataPath) ? this.state.data?.[dataPath] : this.state.data;
    if (data?.length)
      return this.renderRows(data);
    else
      return null;
  };

  renderRows = (data) => {
    const options = this.props.options?.table?.datatable;
    return data.map((rowData, i) => {
      return (
        <tr key={`tr-${i}`}>
        {
          options?.responsive != false
          ? (
            <td className="td-toggle-details pos-rel" data-my-index="-1">
              <div className="position-lc h-95 ml-1px border-l-3 brc-primary-m1">
                {/*<!-- this decorative highlight border will be shown only when table is collapsed (responsive) -->*/}
              </div>
            </td>
          )
          : null
        }
        { this.renderColumns(rowData, i) }
        </tr>
      );
    });
  };

  renderColumns = (rowData, i) => {
    return this.state.cols?.map((col, i) => {
      let sortValue = '';
      try {
        if (col?.properties?.dataType == 'date') {
          const value = DataUtil.fillTemplate(col?.elements?.[0]?.properties?.value, rowData);
          const date = DataUtil.parseDate(value);
          sortValue = date.valueOf();
        }
      } catch (e) { }

      if (sortValue != '' && !isNaN(sortValue))
        return <td key={`td-${i}`} data-order={sortValue} data-my-index={i}>{this.renderComponents(col?.elements, rowData, i)}</td>;
      else
        return <td key={`td-${i}`} data-my-index={i}>{this.renderComponents(col?.elements, rowData, i)}</td>;
      //return <td key={`td-${i}`}><Component component={col} data={rowData} params={this.props.params} /></td>;
    })
  };

  buildTable = (component) => {
    const options = this.props.options?.table?.datatable;
    const cols = this.state.cols;
    //console.log('buildTable', cols)
    return (
      <table width="100%" className="d-style table table-striped text-dark-m1 text-95 border-y-1 brc-black-tp11 collapsed" ref={elem => this.tableElement = elem}>
        <thead>
          <tr>
          {
            options?.responsive != false
            ? <th className="td-toggle-details border-0 bgc-white shadow-sm no-sort" data-orderable="false" data-my-index="-1"></th>
            : null
          }
          {
            cols?.map((col, i) => {
              if(options?.canSelectAll && col?.elements?.[0]?.type == 'CHECKBOX')
                return <th key={`th-${i}`} data-my-col={col?.code} data-my-index={i}>{this.renderComponents(col?.elements, null, i)}</th>
              else
                return <th key={`th-${i}`} data-my-col={col?.code} data-my-index={i}>{col?.properties?.header}</th>
            })
          }
          </tr>
        </thead>
        <tbody>{this.renderData()}</tbody>
      </table>
    )
  };

  draw() {
    const data = this.state.component?.data;
    if (ServiceUtil.hasError(data))
      return <Message messages={data?.messages} />
    else
      return this.buildTable(this.props.component);
  };
}
